import 'date-input-polyfill';
import './static/style/app.scss';

import PiivoPosterEngine from 'piivo-poster-engine/src/lib';
import PiivoUI from 'piivo-ui/src';
import VueCookie from 'vue-cookie';
import VueResource from 'vue-resource';
import VueRouter from 'vue-router';
import Vuelidate from 'vuelidate';

import Auth from '../core/authentication';
import { permission } from '../core/directives/permission';
import extensionPoints, { registerExtensionPoint } from '../core/extensionPoints';
import * as filters from '../core/filters';
import helpers from '../core/helpers';
import HelperManager from '../core/helpers/plugin';
import i18n from '../core/i18n';
import router from '../core/router';
import services from '../core/services';
import { LanguagesManagerService } from '../core/services/languagesManagerService';
import store from '../core/store';
import components from './components';
import { track } from './directives/track';
import PlatformRoutes from './routes';
import { AlertsService } from './services/alertsService';
import { AnalyticsService } from './services/analyticsService';
import { ApplicationsService } from './services/applicationsService';
import { DashboardWidgetsManager } from './services/dashboardWidgetsManager';
import { ModuleManager } from './services/moduleManager';
import { NotificationsService } from './services/notificationsService';
import alerts from './store/alerts';
import {
  ApplicationsModule,
  NAMESPACE as APPLICATIONS_NAMESPACE,
  SET_ERROR_GENERAL,
  SET_LOADING_MODULES,
} from './store/applications';
import { NotificationsModule } from './store/notifications';
import http from './utils/http';

const VuePortal = require('@linusborg/vue-simple-portal');

/**
 * Function to install module in Vue instance
 * @params {Object} Vue: Vue instance
 * @params {Object} settings: object who contains router and store instance
 * */
export function install(Vue) {
  // Install core plugins
  Vue.use(VueRouter);
  Vue.use(VueResource);
  Vue.use(Vuelidate);
  Vue.use(VueCookie);
  Vue.use(HelperManager);
  Vue.use(VuePortal.default, {
    name: 'portal',
  });
  Vue.use(http);

  // Install custom plugins
  Vue.use(Auth);
  Vue.use(PiivoUI);
  Vue.use(PiivoPosterEngine);

  // Install directives
  Vue.directive('track', track);
  Vue.directive('permission', permission);

  // Install filters
  Object.keys(filters).forEach((key) => {
    Vue.filter(key, filters[key]);
  });

  const platformExtension = {
    store,
    router,
    services,
    helpers,
    extensionPoints,
    i18n,
    dashboardWidgetsManager: new DashboardWidgetsManager(),
  };

  // Create injector point
  registerExtensionPoint('platform', platformExtension);

  platformExtension.router.addRoutes(PlatformRoutes);
  platformExtension.store.registerModule('alerts', alerts.AlertsModule);
  platformExtension.store.registerModule('notifications', NotificationsModule);

  if (platformExtension.store.state[APPLICATIONS_NAMESPACE]) {
    platformExtension.store.registerModule(APPLICATIONS_NAMESPACE, ApplicationsModule, {
      preserveState: true,
    });
  } else {
    platformExtension.store.registerModule(APPLICATIONS_NAMESPACE, ApplicationsModule);
  }
  // Make sure to initialize global loaders state asap, in case previous version of module was in localStorage
  platformExtension.store.commit(`${APPLICATIONS_NAMESPACE}/${SET_LOADING_MODULES}`, {
    loadingState: true,
  });
  platformExtension.store.commit(`${APPLICATIONS_NAMESPACE}/${SET_ERROR_GENERAL}`, {
    errorState: false,
  });

  components.forEach((component) => {
    Vue.component(component.name, component);
  });

  const languagesManagerService = new LanguagesManagerService();
  // Make sure the languages are loading before app is rendered since they are always loaded when
  // the app is loaded, even if the state might have been restored
  languagesManagerService.setLoadingLanguages(true);

  const analyticsService = new AnalyticsService();
  analyticsService.start();

  /* Install services */
  platformExtension.services.addService('languages', languagesManagerService);
  platformExtension.services.addService('alerts', new AlertsService());
  platformExtension.services.addService('notifications', new NotificationsService());
  platformExtension.services.addService('analytics', analyticsService);
  platformExtension.services.addService('applications', new ApplicationsService());
  platformExtension.services.addService('moduleManager', new ModuleManager(platformExtension));

  return platformExtension;
}
