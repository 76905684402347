import { IPlatformExtension } from '../extensionPoints/types';
import { IModuleManager, ModuleConfiguration } from './types';

export class ModuleManager implements IModuleManager {
  /**
   * List of registered app modules
   */
  appModules: ModuleConfiguration[] = [];
  /**
   * The platform extension
   */
  platformExtension: IPlatformExtension;

  constructor(platformExtension: IPlatformExtension) {
    this.platformExtension = platformExtension;
  }

  /**
   * @inheritdoc
   */
  registerModule(module: ModuleConfiguration): void {
    this.appModules.push(module);
  }

  /**
   * @inheritdoc
   */
  async loadAll(): Promise<void> {
    for (const mod of this.appModules) {
      await mod.install(this.platformExtension);
    }
  }
}
