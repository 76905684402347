import Router from 'vue-router';

import services from '../services';
import store from '../store';

/**
 * Unknown route name (for tracking).
 */
const UNKNOWN_ROUTE_NAME = 'Unknown';

const allRoutes = [];

const router = new Router({
  mode: 'hash',
  // Each of these routes are loaded asynchronously, when a user first navigates to each corresponding endpoint.
  // The route will load once into memory, the first time it's called, and no more on future calls.
  // This behavior can be observed on the network tab of your browser dev tools.
  routes: allRoutes,
});

/**
 * Before each route hook (guard).
 * @param {Object} to : the target Route Object being navigated to
 * @param {Object} from : the current route being navigated away from
 * @param {Function} next : this function must be called to resolve the hook
 */
router.beforeEach((to, from, next) => {
  // Add guardroute for each routes
  guardRoute(to, from, next);
});

/**
 * After each route hook.
 * @param {Object} route : the target Route Object being navigated to
 */
router.afterEach((route) => {
  // Track page (stop)
  const routeName = route.name || UNKNOWN_ROUTE_NAME;
  services.getService('analytics').stopTrackPage(routeName, route.fullPath);
});

/**
 * GuardRoute method to verify authentication and permissions.
 * @param {Object} to : the target Route Object being navigated to
 * @param {Object} from : the current route being navigated away from
 * @param {Function} next : this function must be called to resolve the hook
 */
function guardRoute(to, from, next) {
  const auth = store.state.auth;

  const isPublicRoute = !!to.meta && !!to.meta.publicRoute;

  // If the page is NOT a public route: verify authentication
  if (!isPublicRoute && !auth.isLoggedIn) {
    // Get user informations to verify if user is logged with external authentication.
    return services
      .getService('auth')
      .loadUserInfos()
      .then(() => {
        if (!auth.isLoggedIn) {
          // User logged out : redirect to login
          return next({
            name: 'login',
            query: { redirect: to.fullPath },
          });
        } else {
          // User logged in (external authentication) : call guardroute again
          return guardRoute(to, from, next);
        }
      });
  }

  // Verify permission
  if (
    to.meta.permission &&
    services.getService('auth').hasPermission(to.meta.permission) === false
  ) {
    // Verify permission : permission denied
    const fail = to.meta.fail || '/404';
    return next(fail);
  }

  // Track page (start)
  const routeName = to.name || UNKNOWN_ROUTE_NAME;
  services.getService('analytics').startTrackPage(routeName);

  return next();
}

export default router;
