<template>
  <pui-authorized-router-link
    :to="to"
    :exact="true"
    tag="li"
    :class="{ 'pui-sidebar-item pui-sidebar-item__root': true, disabled }"
    activeClass="selected"
    :event="''"
  >
    <a class="pui-sidebar-item__link">
      <span :class="{ 'pui-sidebar-item__picto mdi': true, [picto]: !!picto }"></span>
      <p :class="{ visible: label }" class="pui-sidebar-item__label">
        {{ text }}
      </p>
    </a>
  </pui-authorized-router-link>
</template>

<script>
export default {
  name: 'PuiSidebarItem',
  props: {
    text: {
      type: String,
      default: '',
    },
    picto: {
      type: String,
      default: '',
    },
    /**
     * The route to link to
     */
    to: {
      type: Object,
    },
    label: {
      type: Boolean,
      default: true,
    },
    /**
     * Disables the link
     */
    disabled: {
      type: Boolean,
      default: false,
    },
  },
};
</script>
