import Vue from 'vue';

import { Response } from '../types/http';
import { Label } from '../types/i18n';

const API_LANGUAGES__BASE = '/api/piivo/v1/languages';

export const I18N_BASE = '/i18n';

export interface Language extends Label {
  identifier: string;
  createdDate: string;
  updatesDate: string;
  paths: string;
  itemId: string;
  alias: string;
}

export default {
  /**
   * Gets the languages available to the app
   *
   * @returns the api response or error
   */
  getAllLanguages(): Response<Language[]> {
    return Vue.http.get(`${API_LANGUAGES__BASE}`) as Response<Language[]>;
  },

  /**
   *
   * @param locale - the locale whose messages to retrieve
   */
  getLocaleMessages(locale: string): Response<object> {
    return Vue.http.get(`${I18N_BASE}/${locale}.json`) as Response<object>;
  },
};
