import store from '../../core/store';
import { CLEAR_ALERTS, REMOVE_ALERT } from '../store/alerts';
import { IAlertsService } from './types';

/**
 * AlertTypes enumeration.
 */
enum Types {
  INFO = 'info',
  VALID = 'valid',
  WARNING = 'warning',
  ERROR = 'error',
}

/**
 * Alerts Service
 */
export class AlertsService implements IAlertsService {
  /**
   * Add an alert with message and type parameters.
   * @param alertMessage : Alert message
   * @param alertType : Alert type
   * @param displayTime (optional) : Alert display time in milliseconds (defaut 5000)
   */
  private alert(alertMessage: string, alertType: string, displayTime: number | null = 5000) {
    void store.dispatch('addAlert', {
      message: alertMessage,
      type: alertType,
      timeout: displayTime,
    });
  }

  /**
   * Add a valid alert (success message).
   * @param message - message
   * @param displayTime - (optional) Alert display time in milliseconds
   */
  alertSuccess(message: string, displayTime: number | undefined | null = undefined): void {
    this.alert(message, Types.VALID, displayTime);
  }

  /**
   * Add an information alert (info message).
   * @param message - message
   * @param displayTime - (optional) Alert display time in milliseconds
   */
  alertInfo(message: string, displayTime: number | undefined | null = undefined): void {
    this.alert(message, Types.INFO, displayTime);
  }

  /**
   * Add a warning alert (warning message).
   * @param message - message
   * @param displayTime - (optional) Alert display time in milliseconds
   */
  alertWarning(message: string, displayTime: number | undefined | null = undefined): void {
    this.alert(message, Types.WARNING, displayTime);
  }

  /**
   * Add an error alert.
   * @param errorMessage - error message
   * @param displayTime - (optional) Alert display time in milliseconds
   */
  alertError(errorMessage: string, displayTime: number | undefined | null = undefined): void {
    this.alert(errorMessage, Types.ERROR, displayTime);
  }

  /**
   * Clear all alerts (used to clear displayed alerts for example).
   */
  clearAlerts(): void {
    store.commit(CLEAR_ALERTS);
  }

  /**
   *
   * @param alertId - the id of the alert to clear
   */
  clearAlert(alertId: number): void {
    void store.dispatch(REMOVE_ALERT, alertId);
  }
}
