import { USER_LOGOUT } from '../../core/store/modules/coreModule';

export const CLEAR_ALERTS = 'CLEAR_ALERTS';

const ADD_ALERT_MUTATION = 'ADD_ALERT_MUTATION';
const REMOVE_ALERT_MUTATION = 'REMOVE_ALERT_MUTATION';

const ADD_QUEUED_ALERT = 'ADD_QUEUED_ALERT';
const REMOVE_QUEUED_ALERT_MUTATION = 'REMOVE_QUEUED_ALERT_MUTATION';

export const REMOVE_ALERT = 'REMOVE_ALERT';

const alertIdGenerator = (function* () {
  let id = 1;
  while (true) {
    yield id++;
  }
})();

/**
 * Alerts store module.
 */
export const AlertsModule = {
  state: {
    alerts: [],
    queuedAlerts: [],
    maxDisplayedAlerts: 1,
  },

  getters: {
    alerts: (state) => state.alerts.slice().reverse(),
    queuedAlertsCount: (state) => state.queuedAlerts.length,
  },

  actions: {
    /**
     * Adds an alert.
     * @param {object} context - The store context
     * @param {Function} state - This module's state
     * @param {Function} commit - The store commit function
     * @param {Function} dispatch - The store dispatch function
     * @param {*} currentAlert - The alert object to add
     */
    addAlert({ state, commit, dispatch }, currentAlert) {
      currentAlert.id = alertIdGenerator.next().value;

      if (state.alerts.length >= state.maxDisplayedAlerts) {
        commit(ADD_QUEUED_ALERT, currentAlert);
      } else {
        if (currentAlert.timeout) {
          currentAlert._startTimeMs = new Date().getTime();

          window.setTimeout(() => {
            dispatch(REMOVE_ALERT, currentAlert.id);
          }, currentAlert.timeout);
        }

        commit(ADD_ALERT_MUTATION, currentAlert);
      }
    },
    /**
     * Removes an alert, and queues any alert from the queue
     *
     * @param {object} context - The store context
     * @param {Function} state - This module's state
     * @param {Function} commit - The store commit function
     * @param {Function} dispatch - The store dispatch function
     * @param {number} alertId - the alert to remove
     */
    [REMOVE_ALERT]({ state, commit, dispatch }, alertId) {
      commit(REMOVE_ALERT_MUTATION, alertId);

      if (state.queuedAlerts.length) {
        const alert = state.queuedAlerts[0];
        commit(REMOVE_QUEUED_ALERT_MUTATION, alert.id);

        dispatch('addAlert', alert);
      }
    },
    [USER_LOGOUT]: {
      root: true,
      /**
       * Clears state.
       *
       * @param {Object} context - namespaced context
       */
      handler(context) {
        context.state.alerts = [];
      },
    },
  },

  mutations: {
    /**
     * Adds an alert.
     * @param {Object} state - Store state
     * @param {Object} currentAlert - Alert object
     */
    [ADD_ALERT_MUTATION](state, currentAlert) {
      state.alerts.push(currentAlert);
    },
    /**
     * Removes an alert with an id.
     * @param {Object} state - Store state
     * @param {number} alertId - Alert identifier
     */
    [REMOVE_ALERT_MUTATION](state, alertId) {
      for (let i = 0; i < state.alerts.length; i++) {
        if (alertId === state.alerts[i].id) {
          state.alerts.splice(i, 1);
          break;
        }
      }
    },
    /**
     * Adds an alert.
     * @param {Object} state - Store state
     * @param {Object} currentAlert - Alert object
     */
    [ADD_QUEUED_ALERT](state, currentAlert) {
      state.queuedAlerts.push(currentAlert);
    },
    /**
     * Removes a queued alert with an id.
     * @param {Object} state - Store state
     * @param {number} alertId - Alert identifier
     */
    [REMOVE_QUEUED_ALERT_MUTATION](state, alertId) {
      for (let i = 0; i < state.queuedAlerts.length; i++) {
        if (alertId === state.queuedAlerts[i].id) {
          state.queuedAlerts.splice(i, 1);
          break;
        }
      }
    },
    /**
     * Clears alerts.
     * @param {Object} state - Store State
     */
    [CLEAR_ALERTS](state) {
      state.alerts = [];
    },
  },
};

export default {
  AlertsModule,
};
