import i18n from '../../core/i18n';
import router from '../../core/router';
import services from '../../core/services';
import store from '../../core/store';
import api from '../api/applications';
import {
  NAMESPACE as APP_DETAILS_NAMESPACE,
  REMOVE_APP_DETAILS,
  SET_APP_DETAILS,
  SET_CURRENT_APP_ALIAS,
  SET_ERROR_APP,
  SET_LOADING_APP,
} from '../store/applications';
import { ApplicationTypes } from '../types/applications';
import { IAlertsService, IApplicationsService, PiivoApplicationLifecycleService } from './types';

/**
 * Applications Service
 */
export class ApplicationsService implements IApplicationsService {
  /**
   * Get all applications.
   * @returns all applications.
   */
  public async getAllApplications(): Promise<ApplicationTypes.Application[]> {
    try {
      const res = await api.getAllApplications();
      return res.body;
    } catch (err) {
      services
        .getService<IAlertsService>('alerts')
        ?.alertError(i18n.t('platform.applications.error.retrieve_applications') as string);
      throw err;
    }
  }

  /**
   * Get an application by id.
   * @param id - Application Id
   * @returns the corresponding application details
   */
  public async getApplication(
    id: string,
    alias: string
  ): Promise<ApplicationTypes.ApplicationDetails | null> {
    const existingApp = this.getAppDetails(alias);
    if (existingApp) {
      this.setLoadingApp(alias, false);
      return existingApp;
    }

    this.setLoadingApp(alias, true);

    try {
      const res = await api.getApplicationById(id);
      const application = res.body;

      this.setAppDetails(alias, application);
      this.setLoadingApp(alias, false);

      return application;
    } catch (err) {
      this.setAppError(alias, true);
      this.deleteAppDetails(alias);
      services
        .getService<IAlertsService>('alerts')
        ?.alertError(i18n.t('platform.applications.error.retrieve_application_data') as string);

      void router.push({ name: 'settingsError' });
      throw err;
    }
  }

  /**
   * @param appAlias the module whose state to set
   * @param loadingState the new loading state
   */
  private setLoadingApp(appAlias: string, loadingState: boolean): void {
    store.commit(`${APP_DETAILS_NAMESPACE}/${SET_LOADING_APP}`, { appAlias, loadingState });
  }

  /**
   * @param appAlias the module whose state to set
   * @param errorState the new loading state
   */
  private setAppError(appAlias: string, errorState: boolean): void {
    store.commit(`${APP_DETAILS_NAMESPACE}/${SET_ERROR_APP}`, { appAlias, errorState });
  }

  /**
   * @param appAlias the module whose state to set
   * @param details the new details
   */
  private setAppDetails(appAlias: string, details: ApplicationTypes.ApplicationDetails): void {
    store.commit(`${APP_DETAILS_NAMESPACE}/${SET_APP_DETAILS}`, { appAlias, details });
  }

  /**
   * @param appAlias the app's alias
   */
  public setCurrentApplicationAlias(alias: string): void {
    store.commit(`${APP_DETAILS_NAMESPACE}/${SET_CURRENT_APP_ALIAS}`, { alias });
  }

  /**
   * @returns the current application alias
   */
  public getCurrentApplicationAlias(): string {
    // eslint-disable-next-line @typescript-eslint/no-unsafe-return, @typescript-eslint/no-unsafe-member-access, @typescript-eslint/no-unsafe-call
    return store.getters[`${APP_DETAILS_NAMESPACE}/currentApplicationAlias`]();
  }

  /**
   * @param appAlias the module whose state to delete
   */
  private deleteAppDetails(appAlias: string): void {
    store.commit(`${APP_DETAILS_NAMESPACE}/${REMOVE_APP_DETAILS}`, { appAlias });
  }

  /**
   * @returns if modules are loading
   */
  public getIsLoadingModules(): boolean {
    // eslint-disable-next-line @typescript-eslint/no-unsafe-return, @typescript-eslint/no-unsafe-member-access, @typescript-eslint/no-unsafe-call
    return store.getters[`${APP_DETAILS_NAMESPACE}/isLoadingModules`]();
  }

  /**
   * @param appAlias - the module whose state to check
   * @returns if the app's details are loading
   */
  public getIsLoadingApp(appAlias: string): boolean {
    // eslint-disable-next-line @typescript-eslint/no-unsafe-return, @typescript-eslint/no-unsafe-member-access, @typescript-eslint/no-unsafe-call
    return store.getters[`${APP_DETAILS_NAMESPACE}/isLoadingApp`](appAlias);
  }

  /**
   * @returns if there was an error for the main app
   */
  public getHasError(): boolean {
    // eslint-disable-next-line @typescript-eslint/no-unsafe-return, @typescript-eslint/no-unsafe-member-access, @typescript-eslint/no-unsafe-call
    return store.getters[`${APP_DETAILS_NAMESPACE}/hasError`]();
  }

  /**
   * @param appAlias - the module whose state to check
   * @returns if the app details could not be loaded
   */
  public getAppHasError(appAlias: string): boolean {
    // eslint-disable-next-line @typescript-eslint/no-unsafe-return, @typescript-eslint/no-unsafe-member-access, @typescript-eslint/no-unsafe-call
    return store.getters[`${APP_DETAILS_NAMESPACE}/appHasError`](appAlias);
  }

  /**
   * @param appAlias - the module whose details to get
   * @returns the app details
   */
  public getAppDetails(appAlias: string): ApplicationTypes.ApplicationDetails {
    // eslint-disable-next-line @typescript-eslint/no-unsafe-return, @typescript-eslint/no-unsafe-member-access, @typescript-eslint/no-unsafe-call
    return store.getters[`${APP_DETAILS_NAMESPACE}/getAppDetails`](appAlias);
  }

  /**
   * @param appAlias - the module whose parameter to get
   * @param parameterAlias - the parameter alias to get
   * @param parseJSON - if the parameter should be parsed as JSON
   * @returns the app parameter
   */
  public getAppParameter<T = unknown>(
    appAlias: string,
    parameterAlias: string,
    parseJSON?: boolean
  ): T | null | undefined {
    const appDetails = this.getAppDetails(appAlias);
    if (!appDetails || !appDetails.parameters) {
      return null;
    }
    const param = appDetails.parameters.find((param) => param.alias === parameterAlias);
    if (!param) {
      return null;
    }

    if (parseJSON) {
      return JSON.parse(param.value) as T;
    }
    return param.value as unknown as T | null;
  }

  /**
   * Retrieves a nested module's setting
   *
   * @param appAlias the module whose setting to retrieve
   * @param settingPath the path to the desired setting
   * @param parseJSON - if the parameter should be parsed as JSON
   * @returns the value, null, undefined or an error
   */
  public getAppDeepParameter<T = unknown>(
    appAlias: string,
    parameterAlias: string,
    settingPath: string,
    parseJSON?: boolean
  ): T | null | undefined | Error {
    // Error as return type forces the caller to check the type
    const appParameter = this.getAppParameter(appAlias, parameterAlias, parseJSON);
    if (!appParameter) {
      return new Error(`App parameter ${parameterAlias} did not exist`);
    }

    if (!settingPath) {
      return appParameter as T | null | undefined;
    }

    const paths = settingPath.split('.');
    let path: string | number | undefined = paths.shift();
    let setting = appParameter as T | Array<T> | Record<string, T>;

    while (path !== undefined && setting !== null && setting !== undefined) {
      if (Array.isArray(setting) || (setting && typeof setting === 'object')) {
        // eslint-disable-next-line @typescript-eslint/no-explicit-any
        setting = (setting as any)[path];
      } else {
        return new Error(`Could not get module setting at path ${settingPath}`);
      }
      path = paths.shift();
    }

    return setting as T | null | undefined;
  }

  /**
   * @returns the current app
   */
  public getCurrentApp(): ApplicationTypes.ApplicationDetails {
    // eslint-disable-next-line @typescript-eslint/no-unsafe-return, @typescript-eslint/no-unsafe-member-access, @typescript-eslint/no-unsafe-call
    return store.getters[`${APP_DETAILS_NAMESPACE}/getCurrentAppDetails`]();
  }

  // APP LIFECYCLE

  /**
   * App module before create callback.
   * Loads the corresponding Piivo app details and calls service setup methods
   *
   * @param appAlias - the alias of the mounted app module
   * @param serviceNamespace - the namespace for the module's services
   */
  async onModuleBeforeCreate(appAlias: string, serviceNamespace: string): Promise<void> {
    const currentAppAlias = this.getCurrentApplicationAlias();

    // If the current app doesn't match the mounted module, we can't
    // render the app, because some things may depend on the app's settings.
    // - if we already have the app's details, just set it as the current app
    // - else, redirect to the portal so the user is forced to reselect
    //   the app, and thus reload its details

    if (currentAppAlias !== appAlias && !this.getAppDetails(appAlias)) {
      void router.push({ name: 'applications' });
      return;
    }

    this.setLoadingApp(appAlias, true);

    this.setCurrentApplicationAlias(appAlias);

    const nsServices =
      services.getNamespaceServices<PiivoApplicationLifecycleService>(serviceNamespace);
    for (const service of nsServices) {
      await service.onApplicationMount?.();
    }

    this.setLoadingApp(appAlias, false);
  }

  /**
   * App module before destroyed callback.
   * Calls service teardown methods
   *
   * @param appAlias - the alias of the mounted app module
   * @param serviceNamespace - the namespace for the module's services
   */
  onModuleBeforeDestroyed(appAlias: string, serviceNamespace: string): void {
    const nsServices =
      services.getNamespaceServices<PiivoApplicationLifecycleService>(serviceNamespace);
    for (const service of nsServices) {
      // Do not wait for methods because there shouldn't be a teardown order
      void service.onBeforeApplicationDestroyed?.();
    }
  }
}
